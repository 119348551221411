import ColumnLineageModel from '@api/lineage/ColumnLineageModel';
import type { NodeExtraMeta } from '@components/ExploreTree/types';
import mapDataTypesModel from '@models/DataTypesModel/DataTypesModel.v1';
import mapPopularityModel from '@models/PopularityModel/PopularityModel.v1';
import { makeUrl, urlFor } from '@utils/routing';

import truncateNameBreadcrumbs from './utils/truncateNameBreadcrumbs';

const mapColumnLineageModel = (data: any): ColumnLineageModel => {
  const isNested = data?.is_nested;
  const splitName = truncateNameBreadcrumbs({ enabled: isNested, str: data?.name });
  return {
    dataSourceType: data?.data_source_type,
    dataType: data?.data_type,
    dataTypes: mapDataTypesModel(data?.data_types),
    description: data?.description,
    fullName: data?.full_name,
    guid: data?.guid,
    impactScore: data?.impact_score,
    isForeignKey: data?.is_foreign_key,
    isHidden: data?.is_hidden,
    isNested,
    isPrimaryKey: data?.is_primary_key,
    key: data?.key,
    /**
     * The dbt/warehouse columns associated with this warehouse/dbt column respectively
     * If this column is in a DBT Data Source, then this is a list of associated warehouse column
     * If this column is not in a DBT Data Source, then this is a list of associated dbt column
     *
     * Usually is undefined or has 1 elements, never has 0 elements
     */
    linkedObjs: data?.linked_objs,
    name: `${splitName.breadcrumbs}${splitName.name}`,
    objectType: data?.object_type,
    ordinal: data?.ordinal,
    popularity: mapPopularityModel(data?.popularity),
    rank: data?.rank,
    routePath: (() => {
      /**
       * Sigma and Mode has the same objectType which cause troubles
       * with defining a proper router for that bi column object page.
       */
      if (
        data?.data_source_type &&
        data?.data_source_type === 'sigma' &&
        data?.data_type === 'reportquery'
      ) {
        return makeUrl('reportquerysigma', data?.guid, data?.table_guid);
      }

      return urlFor({
        guid: data?.guid,
        objectType: data?.object_type,
        parentGuid: data?.table_guid,
      });
    })(),
    runtimeMeta: {} as NodeExtraMeta,
    sourceEdges: data?.source_edges,
    sourceTableGuids: data?.source_table_guids,
    tableGuid: data?.table_guid,
    tagGuids: data?.tag_guids,
    targetEdges: data?.target_edges,
  };
};

export default mapColumnLineageModel;
