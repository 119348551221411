import React, { useEffect, useMemo, useState } from 'react';
import { IMPACT_SCORE_DESCRIPTION } from '@constants';
import TreeMenu, { TreeNodeInArray } from 'react-simple-tree-menu';
import { RecoilState, useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import getDatasourceTypesOptions from 'src/context/User/getDataSourceTypesOptions';
import { useDebouncedCallback } from 'use-debounce';

import Box from '@components/Box';
import getTreeColumnsConfig from '@components/ExploreSidebar/getTreeColumnsConfig';
import { applyTreeFilters } from '@components/ExploreSidebar/SidebarTree/fiters';
import type { SearchOptions } from '@components/ExploreTree/atoms';
import { desiredZoomTable, exploreOptions, searchOptions } from '@components/ExploreTree/atoms';
import RelevantLineageToggle from '@components/LineageExplore/components/RelevantLineageToggle';
import OrderByButton from '@components/OrderByButton';
import { OrderBy } from '@components/OrderByButton/OrderByButton.styles';
import { POPULARITY_DESCRIPTION } from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import HeaderWithInfo from '@components/Table/Headers/HeaderWithInfo';
import Select from '@components/UI/Select';
import { useModal } from '@context/Modal';
import { useUserContext } from '@context/User';
import ExportToCsvButton from '@pages/DocumentsPage/GlossaryTab/ExportToCsvButton';
import { MakeSpaceProps } from '@styles/mixins/makeSpace';
import wrapString from '@utils/wrapString';

import getTree from '../getTree';
import sidebarInit from '../sidebarInit';
import SidebarTreeItem from '../SidebarTreeItem';
import type { ClickedGuidAtLevel, SidebarProps } from '../types';

import { SortByPanel, TreeContainer } from './SidebarTree.styles';
import SidebarTreeSearchInput from './SidebarTreeSearchInput';

const DEFAULT_EMPTY_TREE = { key: '0', label: 'No results found.', nodes: [] };
const EXPAND_ALL_COUNT_LIMIT = 700;

export type SidebarTreeProps = SidebarProps & {
  counts?: {
    downstream: number | undefined;
    upstream: number | undefined;
  };
  customSearchOptions?: RecoilState<SearchOptions>;
  direction: 'right' | 'left';
  isExpandingAll?: boolean;
  isLoading?: boolean;
  isLoadingExportCsv?: boolean;
  onDataLoad?: (guid: string) => Promise<any>;
  onExportCsvClick?: () => void;
  onUseRelevantLineageChange?: (checked: boolean) => void;
  shouldShowImpactScore?: boolean;
  showDataSourcesFilter?: boolean;
  showDataTypesFilter?: boolean;
  showDescriptions?: boolean;
  showExpandAll?: boolean;
  showRelevantLineageToggle?: boolean;
  showUsage?: boolean;
  startingGuid?: string;
  topBarSpacing?: MakeSpaceProps;
  useRelevantLineage?: boolean;
};

const SidebarTree: React.FC<SidebarTreeProps> = (props) => {
  const {
    counts,
    customSearchOptions,
    direction,
    isExpandingAll,
    isLoading,
    isLoadingExportCsv,
    loadLineage,
    nodeKey: startingKey,
    onExportCsvClick,
    onItemClick,
    onUseRelevantLineageChange,
    shouldShowImpactScore,
    showDataSourcesFilter = false,
    showDescriptions,
    showExpandAll = false,
    showRelevantLineageToggle = true,
    showUsage,
    startingGuid,
    tables,
    topBarSpacing,
    type,
    useRelevantLineage,
    zoomOnItemClick = true,
  } = props;

  const { dataSources } = useUserContext();
  const dataTypeOptions = getDatasourceTypesOptions(dataSources);
  const [selectedDataSourceOptions, setSelectedDataSourceOptions] = useState(dataTypeOptions);
  const treeSearchOptions = customSearchOptions ?? searchOptions;
  const { organization } = useUserContext();
  const [search, setSearch] = useRecoilState(treeSearchOptions);
  const resetSearch = useResetRecoilState(treeSearchOptions);
  const [expandAllState, setExpandAllState] = useState<'collapsed' | 'expanded'>('collapsed');
  const resetExploreOptions = useResetRecoilState(exploreOptions);
  const [clickedGuidAtLevel, setClickedGuid] = useState<ClickedGuidAtLevel>({ '0': 0 });
  const propIndex = ['left', 'right'].indexOf(direction);
  const [keyboardUser, setKeyboardUser] = useState(false);
  const { allNodes, traversalProps } = sidebarInit(props);
  const setZoomToTableId = useSetRecoilState(desiredZoomTable);
  const { MODAL_IDS, openModal } = useModal();
  const setSearchDebounced = useDebouncedCallback((val: SearchOptions) => {
    setSearch(val);
  }, 750);

  const allNodeIds = useMemo(() => {
    return new Set<string | undefined>([...allNodes.map((t) => t.guid)]);
  }, [allNodes]);

  const startingNode = useMemo(() => {
    if (startingKey?.includes('/co_')) {
      const [, columnGuid] = startingKey.split('/');
      return allNodes.find((node) => node.guid === columnGuid);
    }
    return allNodes.find((node) => node.guid === startingKey);
  }, [startingKey, allNodes]);

  const startingDataSourceType = startingNode?.dataSourceType;

  useEffect(() => {
    return () => {
      resetSearch();
      resetExploreOptions();
    };
  }, [resetExploreOptions, resetSearch]);

  const updateSearch = (sortBy: SearchOptions['sortBy'], orderBy: OrderBy) => {
    setSearch((prev) => ({
      ...prev,
      orderBy,
      sortBy,
    }));
  };

  const onSqlButtonClick = (query?: string) => {
    openModal(MODAL_IDS.query, { codeString: query });
  };

  const handleExpandAllClick = () => {
    setExpandAllState((prev) => (prev === 'collapsed' ? 'expanded' : 'collapsed'));
    loadLineage?.(startingGuid ?? '', direction, { maxDepth: 'max' });
  };

  const treeData = useMemo<{
    data: TreeNodeInArray[];
    expandAllKeys: string[] | undefined;
  }>(() => {
    if (!startingKey) {
      return {
        data: [DEFAULT_EMPTY_TREE],
        expandAllKeys: [],
      };
    }

    const expandAllKeys: string[] = [];
    const treeResult = getTree({
      config: {
        allNodeIds,
        allNodes,
        expandAllKeys,
        maxLevel: isExpandingAll ? undefined : Math.max(...Object.values(clickedGuidAtLevel)) + 1,
        propIndex,
        search,
        startingDataSourceType,
        startingKey,
        tables,
        traversalProps,
        type,
      },
      id: startingKey,
      onNodeReturn: (node) => {
        if (node.level > 0) {
          const options = {
            exclude: search.exclude,
            keyword: search.keyword,
            selectedDataSources: showDataSourcesFilter ? selectedDataSourceOptions : undefined,
          };

          return applyTreeFilters(node, options) ? node : undefined;
        }

        return node;
      },
    });

    return {
      data: [treeResult ?? DEFAULT_EMPTY_TREE],
      expandAllKeys,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allNodes,
    startingKey,
    propIndex,
    search.keyword,
    search.exclude,
    search.sortBy,
    search.orderBy,
    selectedDataSourceOptions,
    showDataSourcesFilter,
  ]);

  if (!startingKey) {
    return null;
  }

  const treeColumnsConfig = getTreeColumnsConfig({ showDescriptions, showUsage });

  const disableExpandAll = () => {
    if (direction === 'right') {
      return (counts?.downstream ?? 0) > EXPAND_ALL_COUNT_LIMIT;
    }
    return (counts?.upstream ?? 0) > EXPAND_ALL_COUNT_LIMIT;
  };

  const { useRelevantLineage: organizationUseRelevantLineage } = organization?.settings ?? {};

  const treeKey =
    expandAllState === 'expanded' && isExpandingAll
      ? treeData.expandAllKeys?.join()
      : `${useRelevantLineage ? 'relevant_lineage_' : ''}${startingKey}`;

  return (
    <Box>
      <Box
        alignItems="center"
        compDisplay="flex"
        flexWrap="wrap"
        gap={1}
        justifyContent="space-between"
        mb={0.75}
        mt={2.25}
        {...topBarSpacing}
      >
        <Box
          alignItems="center"
          compDisplay="flex"
          gap={1}
          justifyContent={organizationUseRelevantLineage ? 'flex-start' : 'space-between'}
        >
          <SidebarTreeSearchInput
            onExcludeValueChange={(e) =>
              setSearch({ ...search, exclude: Boolean(e.target.checked) })
            }
            onSearchValueChange={({ target }) => {
              setSearchDebounced({ ...search, keyword: target.value });
            }}
          />
          {showDataSourcesFilter && (
            <Box compWidth="140px">
              <Select
                isDropdown
                isMulti
                label="datasource_types"
                onChange={(options) => {
                  setSelectedDataSourceOptions(options as typeof dataTypeOptions);
                }}
                optionListMaxHeight="165px"
                options={dataTypeOptions}
                optionsFitAnchorWidth={false}
                pl={0.5}
                placeholder="Data Source"
                value={selectedDataSourceOptions}
              />
            </Box>
          )}
          {onExportCsvClick && (
            <ExportToCsvButton isLoading={isLoadingExportCsv} onClick={onExportCsvClick} />
          )}
        </Box>
        {showRelevantLineageToggle && organizationUseRelevantLineage && (
          <RelevantLineageToggle
            checked={useRelevantLineage}
            onChange={(e) => onUseRelevantLineageChange?.(e.target.checked)}
          />
        )}
      </Box>
      <TreeContainer
        className={keyboardUser ? 'keyUser' : ''}
        onClick={() => setKeyboardUser(false)}
        onKeyUp={() => setKeyboardUser(true)}
      >
        <TreeMenu
          key={treeKey}
          data={treeData.data}
          hasSearch={false}
          initialOpenNodes={
            expandAllState === 'expanded' && isExpandingAll ? treeData.expandAllKeys : [startingKey]
          }
        >
          {({ items }) => {
            return (
              <>
                <SortByPanel>
                  <Box compDisplay="flex " compWidth="100%" py={1.5}>
                    {treeColumnsConfig[0] !== null && (
                      <Box {...treeColumnsConfig[0]} gap={1}>
                        <OrderByButton
                          onClick={(orderBy) => {
                            updateSearch('name', orderBy);
                          }}
                          orderBy={search.sortBy === 'name' ? search.orderBy : 'default'}
                        >
                          Name {wrapString((items?.length ?? 1) - 1)}
                        </OrderByButton>
                      </Box>
                    )}
                    {treeColumnsConfig[1] !== null && (
                      <Box {...treeColumnsConfig[1]}>
                        <OrderByButton
                          onClick={(orderBy) => {
                            updateSearch('description', orderBy);
                          }}
                          orderBy={search.sortBy === 'description' ? search.orderBy : 'default'}
                        >
                          Description
                        </OrderByButton>
                      </Box>
                    )}
                    {treeColumnsConfig[2] !== null && (
                      <Box compDisplay="flex" {...treeColumnsConfig[2]}>
                        <OrderByButton
                          onClick={(orderBy) => {
                            updateSearch('usage', orderBy);
                          }}
                          orderBy={search.sortBy === 'usage' ? search.orderBy : 'default'}
                        >
                          Usage
                        </OrderByButton>
                      </Box>
                    )}
                    {treeColumnsConfig[3] !== null && (
                      <Box {...treeColumnsConfig[3]}>
                        <OrderByButton
                          onClick={(orderBy) => {
                            updateSearch('popularity', orderBy);
                          }}
                          orderBy={search.sortBy === 'popularity' ? search.orderBy : 'default'}
                        >
                          <HeaderWithInfo description={POPULARITY_DESCRIPTION} name="Popularity" />
                        </OrderByButton>
                      </Box>
                    )}
                    {shouldShowImpactScore && treeColumnsConfig[4] !== null && (
                      <Box {...treeColumnsConfig[4]}>
                        <OrderByButton
                          onClick={(orderBy) => {
                            updateSearch('impactScore', orderBy);
                          }}
                          orderBy={search.sortBy === 'impactScore' ? search.orderBy : 'default'}
                        >
                          <HeaderWithInfo
                            description={IMPACT_SCORE_DESCRIPTION}
                            name="Impact Score"
                          />
                        </OrderByButton>
                      </Box>
                    )}
                  </Box>
                </SortByPanel>
                <Box as="ul">
                  {items?.map((item) => (
                    <SidebarTreeItem
                      {...item}
                      key={item.key}
                      direction={direction}
                      disableExpandAll={disableExpandAll()}
                      enableHighlight={!search.exclude}
                      expandAllState={expandAllState}
                      isExpandingAll={isExpandingAll}
                      isLoading={isLoading}
                      loadLineage={loadLineage}
                      onClick={() => {
                        if (zoomOnItemClick) {
                          setZoomToTableId(item.tableId);
                        }
                        onItemClick?.(item);

                        setClickedGuid((prev) => ({ ...prev, [item.id]: item.level }));
                      }}
                      onExpandAllClick={handleExpandAllClick}
                      onSqlButtonClick={onSqlButtonClick}
                      searchKeyword={search.keyword}
                      shouldShowImpactScore={shouldShowImpactScore}
                      showExpandAll={showExpandAll && item.level === 0}
                      treeColumnsConfig={treeColumnsConfig}
                      type={type}
                    />
                  ))}
                </Box>
              </>
            );
          }}
        </TreeMenu>
      </TreeContainer>
    </Box>
  );
};

export default React.memo(SidebarTree);
