import React from 'react';
import { DEFAULT_DOCS_URL } from '@constants';

import ChatBotHierarchy from '@components/AppMainSidebar/Hierarchy/ChatbotHierarchy';
import DocumentsHierarchy from '@components/AppMainSidebar/Hierarchy/DocumentsHierarchy';
import FavoritesHierarchy from '@components/AppMainSidebar/Hierarchy/FavoritesHierarchy';
import TagsHierarchy from '@components/AppMainSidebar/Hierarchy/TagsHierarchy';
import TeamsHierarchy from '@components/AppMainSidebar/Hierarchy/TeamsHierarchy';
import Box from '@components/Box';
import NumberBadge from '@components/NumberBadge';
import Notification from '@components/ProfileMenu/Notification';
import SettingsSidebar from '@components/SettingsSidebar/SettingsSidebar.v1';
import flags from '@features';
import theme from '@styles/theme';

import CollectionsHierarchy from '../Hierarchy/CollectionsHierarchy';

import { AiIcon, AiIconFilled } from './AppMainSidebar.styles';
import { SidebarItemConfig, SidebarItemId, SidebarSection } from './AppMainSidebar.types';
import PrimarySidebarItem from './PrimarySidebar/PrimarySidebarItem';
import ProfileMenu from './ProfileMenu';

const collectionsItem: SidebarItemConfig = {
  anchor: (props) => (
    <PrimarySidebarItem
      getTo={() => '/collections'}
      icons={{
        active: 'collection',
        default: 'collection',
      }}
      {...props}
    />
  ),
  baseRoute: ['/collections'],
  children: <CollectionsHierarchy />,
  id: SidebarItemId.collections,
  label: 'Collections',
};

const generalLinks: SidebarSection = {
  id: 'generalLinks',
  items: [
    {
      anchor: (props) =>
        props.useLlmChatBot ? (
          <PrimarySidebarItem
            getTo={({ currentPath }) => {
              return currentPath.startsWith('/chatbot') ? undefined : '/chatbot';
            }}
            icons={{
              active: <AiIconFilled height="20px" width="20px" />,
              default: <AiIcon height="20px" width="20px" />,
            }}
            {...props}
          />
        ) : null,
      baseRoute: ['/chatbot'],
      children: <ChatBotHierarchy />,
      id: SidebarItemId.chatbot,
      label: 'Ask AI',
    },
    {
      anchor: (props) => (
        <Notification shouldUseNewLayout>
          {({ isAnyUnread, isOpen, setNotificationOpen, unreadNotificationsCount }) => (
            <Box compWidth="100%" onClick={() => setNotificationOpen(true)}>
              <PrimarySidebarItem
                icons={{
                  active: isAnyUnread ? 'bell-alert-filled' : 'bell-filled',
                  default: isAnyUnread ? 'bell-alert' : 'bell',
                }}
                {...props}
                isActive={isOpen}
              >
                {isAnyUnread && !props.isCollapsed && (
                  <NumberBadge
                    backgroundColor={theme.colors.v1.primary[700]}
                    color="white"
                    lineHeight="unset"
                    value={unreadNotificationsCount}
                  />
                )}
              </PrimarySidebarItem>
            </Box>
          )}
        </Notification>
      ),
      id: SidebarItemId.notifications,
      label: 'Notifications',
    },
    {
      anchor: (props) => (
        <PrimarySidebarItem
          icons={{
            active: 'favorite',
            default: 'favorite-outlined',
          }}
          {...props}
        />
      ),
      children: <FavoritesHierarchy />,
      id: SidebarItemId.favorites,
      label: 'Favorites',
    },
  ],
  order: 0,
};

if (flags.enable_collections) {
  generalLinks.items.unshift(collectionsItem);
}

const docsTagsTeams: SidebarSection = {
  id: 'docsTagsTeams',
  items: [
    {
      anchor: (props) => (
        <PrimarySidebarItem
          getTo={() => '/docs/tabs/all'}
          icons={{
            active: 'all-docs-filled',
            default: 'all-docs',
          }}
          {...props}
        />
      ),
      baseRoute: ['/docs'],
      children: <DocumentsHierarchy />,
      id: SidebarItemId.docs,
      label: 'Docs',
    },
    {
      anchor: (props) => (
        <PrimarySidebarItem
          getTo={() => '/tags'}
          icons={{
            active: 'tag-filled',
            default: 'tag-outline',
          }}
          {...props}
        />
      ),
      baseRoute: ['/tags'],
      children: <TagsHierarchy />,
      id: SidebarItemId.tags,
      label: 'Tags',
    },
    {
      anchor: (props) => (
        <PrimarySidebarItem
          getTo={() => '/teams'}
          icons={{
            active: 'team-filled',
            default: 'team',
          }}
          {...props}
        />
      ),
      baseRoute: ['/teams', '/profiles'],
      children: <TeamsHierarchy />,
      id: SidebarItemId.teams,
      label: 'Teams',
    },
  ],
  order: 2,
};

export const SETTINGS_BASE_ROUTE = ['/admin', '/settings', '/mask', '/feature-flags'];
const settingsProfile: SidebarSection = {
  id: 'settingsProfile',
  items: [
    {
      anchor: (props) => (
        <PrimarySidebarItem
          externalLink
          getTo={() => DEFAULT_DOCS_URL}
          icons={{
            active: 'help-outlined',
            default: 'help-outlined',
          }}
          {...props}
        />
      ),
      id: SidebarItemId.helpDocs,
      label: 'Help Docs',
    },
    {
      anchor: (props) => (
        <PrimarySidebarItem
          getTo={() => '/settings'}
          icons={{
            active: 'settingV1',
            default: 'setting-outlined',
          }}
          {...props}
        />
      ),
      baseRoute: SETTINGS_BASE_ROUTE,
      children: <SettingsSidebar />,
      id: SidebarItemId.settings,
      label: 'Settings',
      secondarySidebarTitleHeight: 'small',
      titleSpacing: { mb: 0.9 },
    },
    {
      anchor: ({ isCollapsed }) => <ProfileMenu showName={!isCollapsed} />,
      children: <SettingsSidebar />,
      id: SidebarItemId.profileMenu,
      label: 'Settings',
      titleSpacing: { mb: 0.9 },
    },
  ],
  order: 3,
  position: 'bottom',
};

const mainSidebarSections = {
  docsTagsTeams,
  generalLinks,
  settingsProfile,
};

export type MainSidebarSections = { [key: string]: SidebarSection } & typeof mainSidebarSections;

export default mainSidebarSections;
