import TableLineageModel from '@api/lineage/TableLineageModel';
import mapDataTypesModel from '@models/DataTypesModel/DataTypesModel.v1';
import mapPopularityModel from '@models/PopularityModel/PopularityModel.v1';

const mapTableLineageModel = (data: any): TableLineageModel | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    columns: data?.columns,
    componentIdentifier: data?.component_identifier,
    dataSourceType: data?.data_source_type,
    dataType: data?.data_type,
    dataTypes: mapDataTypesModel(data?.data_types),
    description: data?.description,
    fullName: data?.full_name,
    guid: data?.guid,
    impactScore: data?.impact_score,
    isHidden: data?.is_hidden,
    key: data?.key,
    linkedObjs: data?.linked_objs,
    name: data?.name,
    objectType: data?.object_type,
    popularity: mapPopularityModel(data?.popularity),
    query: data?.query,
    rank: data?.rank,
    sourceEdges: data?.source_edges,
    sourceTableGuids: data?.source_table_guids,
    tagGuids: data?.tag_guids,
    targetEdges: data?.target_edges,
    targetTableGuids: data?.target_table_guids,
  };
};

export default mapTableLineageModel;
