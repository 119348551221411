import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TreeNodeInArray } from 'react-simple-tree-menu';
import { RecoilState, useRecoilState, useResetRecoilState } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';

import mapLineageExploreModel from '@api/lineage/LineageExploreModel.v1';
import Box from '@components/Box';
import getTreeColumnsConfig from '@components/ExploreSidebar/getTreeColumnsConfig';
import type { SearchOptions } from '@components/ExploreTree/atoms';
import { exploreOptions, searchOptions } from '@components/ExploreTree/atoms';
import OrderByButton from '@components/OrderByButton';
import { OrderBy } from '@components/OrderByButton/OrderByButton.styles';
import TreeV2 from '@components/Tree/Tree.v2';
import { ExpandData } from '@components/Tree/Tree.v2/Tree.v2';
import useOverrideCmdCtrlKey from '@hooks/useOverrideCmdCtrlKey';
import ExportToCsvButton from '@pages/DocumentsPage/GlossaryTab/ExportToCsvButton';

import getTree from '../getTree';
import sidebarInit from '../sidebarInit';
import SidebarTreeItem from '../SidebarTreeItem';
import type { SidebarProps } from '../types';

import { SortByPanel } from './SidebarTree.styles';
import SidebarTreeSearchInput from './SidebarTreeSearchInput';

const DEFAULT_EMPTY_TREE = { key: '0', label: 'No results found.', nodes: [] };
const EXPAND_ALL_COUNT_LIMIT = 700;

export type SidebarTreeProps = SidebarProps & {
  counts?: {
    downstream: number | undefined;
    upstream: number | undefined;
  };
  customSearchOptions?: RecoilState<SearchOptions>;
  direction: 'right' | 'left';
  filterLevelFrom?: number;
  isExpandingAll?: boolean;
  isLoading?: boolean;
  isLoadingExportCsv?: boolean;
  onDataLoad?: (guid: string) => Promise<any>;
  onExportCsvClick?: () => void;
  showDescriptions?: boolean;
  showExpandAll?: boolean;
  showUsage?: boolean;
  startingGuid?: string;
};

const SidebarTree: React.FC<SidebarTreeProps> = (props) => {
  const { customSearchOptions } = props;
  const treeSearchOptions = customSearchOptions ?? searchOptions;
  const [search, setSearch] = useRecoilState(treeSearchOptions);
  const resetSearch = useResetRecoilState(treeSearchOptions);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [expandAllState, setExpandAllState] = useState<'collapsed' | 'expanded'>('collapsed');
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [options] = useRecoilState(exploreOptions);
  const resetExploreOptions = useResetRecoilState(exploreOptions);
  const {
    counts,
    direction,
    isExpandingAll,
    isLoading,
    isLoadingExportCsv,
    loadLineage,
    nodeKey: startingKey,
    nodeKey,
    onDataLoad,
    onExportCsvClick,
    showDescriptions,
    showExpandAll = false,
    showUsage,
    startingGuid,
    tables,
    type,
  } = props;
  const propIndex = ['left', 'right'].indexOf(direction);
  const { allNodes, traversalProps } = sidebarInit(props);
  const setSearchDebounced = useDebouncedCallback((val: SearchOptions) => {
    setSearch(val);
  }, 500);

  const allNodeIds = useMemo(() => {
    return new Set<string | undefined>([...allNodes.map((t) => t.guid)]);
  }, [allNodes]);

  const startingNode = useMemo(() => {
    if (startingKey?.includes('/co_')) {
      const [, columnGuid] = startingKey.split('/');
      return allNodes.find((node) => node.guid === columnGuid);
    }
    return allNodes.find((node) => node.guid === startingKey);
  }, [startingKey, allNodes]);

  const startingDataSourceType = startingNode?.dataSourceType;

  const handleShowInputToggle = useDebouncedCallback(() => {
    setIsInputVisible(!isInputVisible);

    if (!isInputVisible) {
      searchInputRef?.current?.focus();
    }
  }, 50);

  useOverrideCmdCtrlKey({ onPress: handleShowInputToggle });

  useEffect(() => {
    return () => {
      resetSearch();
      resetExploreOptions();
    };
  }, [resetExploreOptions, resetSearch]);

  const updateSearch = (sortBy: SearchOptions['sortBy'], orderBy: OrderBy) => {
    setSearch((prev) => ({
      ...prev,
      orderBy,
      sortBy,
    }));
  };

  const handleExpandAllClick = () => {
    setExpandAllState((prev) => (prev === 'collapsed' ? 'expanded' : 'collapsed'));
    loadLineage?.(startingGuid ?? '', direction, { maxDepth: 'max' });
  };

  const treeData = useMemo<{
    data: TreeNodeInArray[];
    expandAllKeys: string[] | undefined;
  }>(() => {
    if (!startingKey) {
      return {
        data: [DEFAULT_EMPTY_TREE],
        expandAllKeys: [],
      };
    }

    const expandAllKeys: string[] = [];
    const treeResult = getTree({
      config: {
        allNodeIds,
        allNodes,
        expandAllKeys,
        maxLevel: isExpandingAll ? undefined : 1,
        propIndex,
        search,
        startingDataSourceType,
        startingKey,
        tables,
        traversalProps,
        type,
      },
      id: startingKey,
    });

    return {
      data: [treeResult ?? DEFAULT_EMPTY_TREE],
      expandAllKeys,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, allNodes, startingKey, propIndex, search]);

  if (!startingKey) {
    return null;
  }

  const treeColumnsConfig = getTreeColumnsConfig({ showDescriptions, showUsage });

  const disableExpandAll = () => {
    if (direction === 'right') {
      return (counts?.downstream ?? 0) > EXPAND_ALL_COUNT_LIMIT;
    }
    return (counts?.upstream ?? 0) > EXPAND_ALL_COUNT_LIMIT;
  };

  const allowCaret = ({ original }: { original: any }, { parentKey }: { parentKey: string }) => {
    const edges =
      (direction === 'left' ? original?.sourceTableGuids : original?.targetTableGuids) ?? [];

    // recursive relation detected
    if (edges.includes(parentKey)) {
      return false;
    }

    return original.guid !== startingKey && edges?.length > 0;
  };

  const allowDataLoad = ({ original }: { original: any }, expandData: ExpandData) => {
    return (
      allowCaret({ original }, expandData) &&
      (!original.children || original.children?.length === 0)
    );
  };

  return (
    <Box>
      <Box
        alignItems="center"
        compDisplay="flex"
        justifyContent="space-between"
        mb={0.75}
        mt={2.25}
      >
        <SidebarTreeSearchInput
          onExcludeValueChange={(e) => setSearch({ ...search, exclude: Boolean(e.target.checked) })}
          onSearchValueChange={({ target }) => {
            setSearchDebounced({ ...search, keyword: target.value });
          }}
        />
        {onExportCsvClick && (
          <ExportToCsvButton isLoading={isLoadingExportCsv} onClick={onExportCsvClick} />
        )}
      </Box>
      <SortByPanel>
        <Box compDisplay="flex " compWidth="100%" py={1.5}>
          {treeColumnsConfig[0] !== null && (
            <Box gap={1} pl={2} pr={2} {...treeColumnsConfig[0]}>
              <OrderByButton
                onClick={(orderBy) => {
                  updateSearch('name', orderBy);
                }}
                orderBy={search.sortBy === 'name' ? search.orderBy : 'default'}
              >
                Name
              </OrderByButton>
            </Box>
          )}
          {treeColumnsConfig[1] !== null && (
            <Box {...treeColumnsConfig[1]}>
              <OrderByButton
                onClick={(orderBy) => {
                  updateSearch('description', orderBy);
                }}
                orderBy={search.sortBy === 'description' ? search.orderBy : 'default'}
              >
                Description
              </OrderByButton>
            </Box>
          )}
          {treeColumnsConfig[2] !== null && (
            <Box compDisplay="flex" {...treeColumnsConfig[2]}>
              <OrderByButton
                onClick={(orderBy) => {
                  updateSearch('usage', orderBy);
                }}
                orderBy={search.sortBy === 'usage' ? search.orderBy : 'default'}
              >
                Usage
              </OrderByButton>
            </Box>
          )}
          {treeColumnsConfig[3] !== null && (
            <Box {...treeColumnsConfig[3]}>
              <OrderByButton
                onClick={(orderBy) => {
                  updateSearch('popularity', orderBy);
                }}
                orderBy={search.sortBy === 'popularity' ? search.orderBy : 'default'}
              >
                Popularity
              </OrderByButton>
            </Box>
          )}
        </Box>
      </SortByPanel>
      <TreeV2
        allowCaret={allowCaret}
        allowDataLoad={allowDataLoad}
        enableHighlight={false}
        expandedKeys={
          expandAllState === 'expanded' && isExpandingAll ? treeData.expandAllKeys : [nodeKey ?? '']
        }
        getKey={(item) => item.guid}
        onDataLoad={async ({ original }) => {
          const result = await onDataLoad?.(original.guid);
          // The API response includes the node itself, but the TreeV2 crashes is we return it in the onDataLoad
          return mapLineageExploreModel(result)?.tables.filter(
            (item) => item.guid !== original.guid,
          );
        }}
        onFilterDataLoad={(original, key) => original.guid !== key}
        renderLabel={({ level, original }) => {
          return (
            <SidebarTreeItem
              {...original}
              key={original.key}
              direction={direction}
              disableExpandAll={disableExpandAll()}
              enableHighlight={!search.exclude}
              expandAllState={expandAllState}
              isExpandingAll={isExpandingAll}
              isLoading={isLoading}
              loadLineage={loadLineage}
              onExpandAllClick={handleExpandAllClick}
              searchKeyword={search.keyword}
              showExpandAll={showExpandAll && level === 0}
              treeColumnsConfig={treeColumnsConfig}
            />
          );
        }}
        treeData={treeData.data}
      />
    </Box>
  );
};

export default React.memo(SidebarTree);
