import React, { useState } from 'react';
import { IMPACT_SCORE_DESCRIPTION } from '@constants';
import { Cell } from 'react-table';

import { LookerExploreModel } from '@api/explores/LookerExploreModel';
import DescriptionCell from '@components/Table/Cells/DescriptionCell';
import EditableTaggedItemCell from '@components/Table/Cells/EditableTaggedItemCell';
import FormattedNumberCell from '@components/Table/Cells/FormattedNumberCell';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import TooltipCell from '@components/Table/Cells/TooltipCell';
import UserCell from '@components/Table/Cells/UserCell';
import HeaderWithInfo from '@components/Table/Headers/HeaderWithInfo';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { useUserContext } from '@context/User';
import flags from '@features';
import { Filter } from '@utils';
import { getPopularityNormalized } from '@utils/popularity';

const INITIAL_SORT_STATE = [
  {
    desc: true,
    id: 'popularity',
  },
];

interface ExploresTableProps {
  data?: LookerExploreModel[];
  filterService: Filter.FilterServiceInterface;
  isDataSourceEditable: boolean;
  itemCount?: number;
  selectedRowIds: { [guid: string]: boolean };
  setSelectedRowIds: React.Dispatch<React.SetStateAction<{}>>;
  totalPages: number;
}

const ExploresTable: React.FC<ExploresTableProps> = ({
  data,
  filterService,
  isDataSourceEditable,
  itemCount = 0,
  selectedRowIds,
  setSelectedRowIds,
  totalPages,
}) => {
  const { organization } = useUserContext();
  const { useGroupLabel = false, useImpactScore = false } = organization?.settings ?? {};
  const [isShowFilter, setShowFilter] = useState(false);
  const toggleFilter = React.useCallback(() => setShowFilter((prev) => !prev), [setShowFilter]);
  const { changePage, filter, globalSearch, sort } = filterService;

  const getHiddenColumns = () => {
    const hiddenColumns = ['businessOwner', 'technicalOwner', 'impactScore'];

    if (useGroupLabel) {
      hiddenColumns.push('model');
    } else {
      hiddenColumns.push('folder');
    }

    return hiddenColumns;
  };

  const columns: ColumnConfig<LookerExploreModel>[] = React.useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: ({ row: { original: folder } }: Cell<LookerExploreModel>) => {
          return (
            <LinkedCell
              customUrl={folder.group?.routePath}
              item={folder.group!}
              showIcon={flags.looker_icon_updates_folder_explore}
            />
          );
        },
        Header: 'Folder',
        accessor: 'groupLabel',
        id: 'folder',
        width: '110%',
      },
      {
        Cell: TooltipCell,
        Header: 'Model',
        accessor: (d) => d.lookMLModel?.label,
        disableFilters: true,
        id: 'model',
        width: '110%',
      },
      {
        Cell: (props: Cell<LookerExploreModel>) => {
          const { row } = props;
          const explore = row.original;
          return (
            <LinkedCell
              {...props}
              item={explore}
              itemName={explore.name}
              showDataTypeTooltip
              showIcon
            />
          );
        },
        Header: `Explore (${itemCount})`,
        accessor: 'name',
        disableFilters: true,
        disableHiding: true,
        id: 'name',
        width: '135%',
      },
      {
        Cell: (props: Cell<LookerExploreModel>) => {
          const { row } = props;
          const explore = row.original;
          return (
            <DescriptionCell
              {...props}
              aiDescription={explore.aiDescription}
              dataSourceType={explore.dataTypes?.dataSourceType}
              description={explore.description}
              descriptionSource={explore.descriptionSource}
              guid={explore.guid}
              ingestedDescription={explore.ingestedDescription}
              isDataSourceEditable={isDataSourceEditable}
              name={explore.name}
              richtextDescription={explore.richtextDescription}
              suggestedDescription={explore.suggestedDescription}
              suggestedDescriptionSource={explore.suggestedDescriptionSource}
              suggestedDescriptionSourceObj={explore.suggestedDescriptionSourceObject}
              userDescription={explore.userDescription}
            />
          );
        },
        Header: 'Description',
        accessor: 'description',
        disableFilters: true,
        id: 'description',
        width: '124.5%',
      },
      {
        Cell: (props: Cell<LookerExploreModel>) => {
          const { row } = props;
          const explore = row.original;
          return (
            <EditableTaggedItemCell
              {...props}
              isDataSourceEditable={isDataSourceEditable}
              obj={explore}
            />
          );
        },
        Header: 'Tags',
        accessor: (d) => d.taggedItems,
        disableFilters: true,
        disableSortBy: true,
        id: 'tags',
        width: '120%',
      },
      {
        Cell: ({ row: { original } }: Cell<LookerExploreModel>) => (
          <UserCell user={original.businessOwner?.obj} />
        ),
        Header: 'Business Owner',
        accessor: (d) => d.businessOwner?.obj?.fullName,
        disableFilters: true,
        id: 'businessOwner',
        width: '120%',
      },
      {
        Cell: ({ row: { original } }: Cell<LookerExploreModel>) => (
          <UserCell user={original.technicalOwner?.obj} />
        ),
        Header: 'Technical Owner',
        accessor: (d) => d.technicalOwner?.obj?.fullName,
        disableFilters: true,
        id: 'technicalOwner',
        width: '120%',
      },
      {
        Cell: (props: Cell<LookerExploreModel>) => {
          const { row } = props;
          const explore = row.original;
          return <PopularityCell {...props} popularity={explore.popularity} />;
        },
        Header: PopularityCellHeader,
        accessor: (d) => getPopularityNormalized(d?.popularity?.popularity),
        disableFilters: true,
        disableResizing: true,
        id: 'popularity',
        sortDescFirst: true,
        width: 120,
      },
      ...(useImpactScore
        ? [
            {
              Cell: ({ row: { original } }: Cell<LookerExploreModel>) => (
                <FormattedNumberCell number={original.downstreamObjectsCounts?.impactScore} />
              ),
              Header: <HeaderWithInfo description={IMPACT_SCORE_DESCRIPTION} name="Impact Score" />,
              accessor: (d: Partial<LookerExploreModel>) => d.downstreamObjectsCounts?.impactScore,
              disableFilters: true,
              disableHiding: false,
              dropdownCheckboxLabel: 'Impact Score',
              id: 'impactScore',
              sortDescFirst: true,
              width: 135,
            },
          ]
        : []),
    ],
    [isDataSourceEditable, itemCount, useImpactScore],
  );

  const getRowId = React.useCallback((row: Partial<LookerExploreModel>) => row.guid!, []);

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        compact
        data={data || []}
        getRowId={getRowId}
        initialState={{
          hiddenColumns: getHiddenColumns(),
          pageIndex: filter.page ? filter.page - 1 : 0,
          selectedRowIds,
          sortBy: INITIAL_SORT_STATE,
        }}
        loading={data === undefined}
        manualGlobalFilter
        manualPagination
        manualSortBy
        setGlobalFilter={globalSearch}
        setSelectedRowIds={setSelectedRowIds}
        setSortBy={sort}
        showFilter={isShowFilter}
        sortable
        stickyHeader
        toggleFilter={toggleFilter}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default React.memo<ExploresTableProps>(ExploresTable);
