import { orderBy } from 'lodash';

enum MainFeature {
  AI_ASSIST = 'AI Assist',
  BILLING = 'Billing',
  COLLECTIONS = 'Collections',
  COLUMN_PAGE = 'Column page',
  COMMENTS = 'Comments',
  COST_ANALYSIS = 'Cost Analysis',
  FULL_STORY = 'FullStory',
  INTEGRATION = 'Integration',
  INTERCOM = 'Intercom',
  LAYOUT = 'Layout',
  LINEAGE = 'Lineage',
  LOOKER = 'Looker',
  OPEN_IN_DJANGO = 'Open in Django',
  PBAC = 'PBAC',
  SIGN_IN = 'Sign in',
  TAGS = 'Tags',
}

const featureFlagsList = orderBy(
  [
    {
      createdAt: '2021-12-20',
      description: 'Billing',
      mainFeature: MainFeature.BILLING,
      value: 'billing',
    },
    {
      createdAt: '2023-05-26',
      description: 'Deleted label',
      mainFeature: MainFeature.COLUMN_PAGE,
      value: 'deleted_label',
    },
    {
      createdAt: '2023-03-13',
      description: 'Enable intercom',
      mainFeature: MainFeature.INTERCOM,
      value: 'enable_intercom',
    },
    {
      createdAt: '2022-10-28',
      description: 'Hide Google login in Login page',
      mainFeature: MainFeature.SIGN_IN,
      value: 'hide_google_login',
    },
    {
      createdAt: '2024-04-29',
      description: 'Looker icon updates on folder and explore pages',
      mainFeature: MainFeature.LOOKER,
      value: 'looker_icon_updates_folder_explore',
    },
    {
      createdAt: '2021-04-021',
      description: 'Looker view lineage',
      mainFeature: MainFeature.LOOKER,
      value: 'looker_view_lineage',
    },
    {
      createdAt: '2022-09-26',
      description: 'Open in the asset in Django Admin',
      internalTool: true,
      mainFeature: MainFeature.OPEN_IN_DJANGO,
      value: 'open_in_django',
    },
    {
      createdAt: '2023-03-23',
      description: 'Show PBAC Except For Tags',
      mainFeature: MainFeature.PBAC,
      value: 'show_pbac_except_for_tags',
    },
    {
      createdAt: '2023-03-28',
      description: 'Tag propagation modal',
      mainFeature: MainFeature.TAGS,
      value: 'tag_propagation_modal',
    },
    {
      createdAt: '2023-09-06',
      description: 'Show dashboards/Explores sub tab on looker dsuser page',
      mainFeature: MainFeature.LOOKER,
      value: 'dashboards_explores_sub_tabs_dsuser_profile',
    },
    {
      createdAt: '2023-10-30',
      description: 'Bulk AI Assist',
      mainFeature: MainFeature.AI_ASSIST,
      value: 'bulk_ai_assist',
    },
    {
      createdAt: '2023-11-29',
      description: 'Show Cost Analysis Table Scatter Chart',
      mainFeature: MainFeature.COST_ANALYSIS,
      value: 'show_cost_analysis_table_scatter_chart',
    },
    {
      createdAt: '2023-12-08',
      description: 'Enable FullStory tracking',
      mainFeature: MainFeature.FULL_STORY,
      value: 'enable_fullstory_tracking',
    },
    {
      createdAt: '2024-04-16',
      description: 'Show new lineage counts',
      mainFeature: MainFeature.LINEAGE,
      value: 'new_lineage_counts',
    },
    {
      createdAt: '2024-04-25',
      description: 'Enable new lineage debug mode',
      internalTool: true,
      mainFeature: MainFeature.LINEAGE,
      value: 'new_lineage_debug_mode',
    },
    {
      createdAt: '2024-06-17',
      description: 'Show lineage dev options',
      internalTool: true,
      mainFeature: MainFeature.LINEAGE,
      value: 'show_lineage_dev_options',
    },
    {
      createdAt: '2024-09-02',
      description: 'Use TreeV2 in the lineage list view',
      mainFeature: MainFeature.LINEAGE,
      value: 'lineage_list_tree_v2',
    },
    {
      createdAt: '2024-10-07',
      description: 'Use new layout',
      mainFeature: MainFeature.LAYOUT,
      value: 'use_new_layout_fe',
    },
    {
      createdAt: '2024-10-15',
      description: 'Show Header Bottom Border',
      mainFeature: MainFeature.LAYOUT,
      value: 'show_header_bottom_border',
    },
    {
      createdAt: '2024-11-05',
      description: 'Expand all column - mode column and mode all',
      mainFeature: MainFeature.LINEAGE,
      value: 'lineage_expand_all_column_mode_column_and_all',
    },
    {
      createdAt: '2024-11-12',
      description: 'Enable collections',
      mainFeature: MainFeature.COLLECTIONS,
      value: 'enable_collections',
    },
  ] as const,
  ['name'],
  ['asc'],
);

export type FeatureFlag = (typeof featureFlagsList)[number];

export type FeatureFlagsKeysType = (typeof featureFlagsList)[number]['value'];

export default featureFlagsList;
