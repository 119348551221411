import { RBACPolicyModel } from '@api/rbacPolicy';
import { SearchModel } from '@api/search/SearchModel';
import { QuickSearchIndexFilter } from '@api/search/types';
import type { DatasourceTabV1 } from '@components/SearchBar/DatasourceTabs/config';
import { tabConfig } from '@components/SearchBar/DatasourceTabs/config';
import DataTypesModel from '@models/DataTypesModel';

export interface RBACPolicyModalProps {
  onClose?: () => void;
  onSuccess?: () => void;
  policy?: RBACPolicyModel;
}

export const assignToWildcard: SearchModel = {
  dataTypes: {
    icons: {
      dataType: 'select-star-colored',
    },
  } as DataTypesModel,
  description: '',
  guid: '*',
  id: '*',
  name: 'Everyone',
  objectType: 'wildcard' as SearchModel['objectType'],
  score: 100,
  searchName: 'Everyone',
  taggedItems: [],
  title: '',
  typeDisplay: '',
  typeLabel: '',
};

export const accessToWildcard: SearchModel = {
  dataTypes: {
    icons: {
      dataType: 'select-star-colored',
    },
  } as DataTypesModel,
  description: '',
  guid: '*',
  id: '*',
  name: 'All',
  objectType: 'wildcard' as SearchModel['objectType'],
  score: 100,
  searchName: '(everything)',
  taggedItems: [],
  title: '',
  typeDisplay: '',
  typeLabel: '',
};

export const accessToAllDocs: SearchModel = {
  dataTypes: {
    icons: {
      dataType: 'all-docs',
    },
  } as DataTypesModel,
  description: '',
  guid: 'all-docs',
  id: 'all-docs',
  name: 'All Docs',
  objectType: 'wildcard' as SearchModel['objectType'],
  score: 100,
  searchName: 'All Docs',
  taggedItems: [],
  title: '',
  typeDisplay: '',
  typeLabel: '',
};

export const assignToFilters: QuickSearchIndexFilter[] = [
  {
    indexes: ['users', 'teams'],
  },
];

export const databaseCustomTab: DatasourceTabV1 = {
  filters: [
    {
      indexes: ['data-sources', 'databases', 'dbt-tests', 'schemata', 'databricks_folders'],
    },
  ],
  icon: 'database',
  name: 'Data',
  subtabs: [
    { indexes: ['data-sources'], name: 'Data Source' },
    { indexes: ['databases'], name: 'Database' },
    { indexes: ['schemata'], name: 'Schema' },
    { indexes: ['dbt-tests'], name: 'dbt Test' },
    { indexes: ['databricks_folders'], name: 'Folder' },
  ],
};

export const dataStudioCustomTab: DatasourceTabV1 = {
  filters: [{ indexes: ['data_studio_dashboards'] }],
  icon: 'data-studio',
  name: 'Looker Studio',
  subtabs: [
    { indexes: ['data_studio_dashboards'], name: 'Dashboard' },
    { indexes: ['data_studio_folders'], name: 'Folder' },
  ],
};

export const lookerCustomTab: DatasourceTabV1 = {
  filters: [
    {
      indexes: [
        'explores',
        'looker_dashboards',
        'looker_folders',
        'looker_groups',
        'looker_looks',
        'lookml_views',
      ],
    },
  ],
  icon: 'looker',
  name: 'Looker',
  subtabs: [
    { indexes: ['looker_dashboards'], name: 'Dashboard' },
    { indexes: ['looker_folders'], name: 'Folder' },
    { indexes: ['looker_groups'], name: 'Group' },
    { indexes: ['looker_looks'], name: 'Look' },
    { indexes: ['lookml_views'], name: 'View' },
    { indexes: ['explores'], name: 'Explore' },
  ],
};

export const metabaseCustomTab: DatasourceTabV1 = {
  filters: [
    {
      indexes: ['metabase_dashboards', 'metabase_folders'],
    },
  ],
  icon: 'metabase',
  name: 'Metabase',
  subtabs: [
    { indexes: ['metabase_dashboards'], name: 'Dashboard' },
    { indexes: ['metabase_folders'], name: 'Folder' },
  ],
};

export const powerbiCustomTab: DatasourceTabV1 = {
  filters: [
    {
      indexes: [
        'power_bi_dashboards',
        'power_bi_folders',
        'power_bi_reports',
        'power_bi_datasets',
        'power_bi_dataflows',
      ],
    },
  ],
  icon: 'powerbi',
  name: 'Power BI',
  subtabs: [
    { indexes: ['power_bi_dashboards'], name: 'Dashboard' },
    { indexes: ['power_bi_folders'], name: 'Folder' },
    { indexes: ['power_bi_reports'], name: 'Report' },
    { indexes: ['power_bi_datasets'], name: 'Dataset' },
    { indexes: ['power_bi_dataflows'], name: 'Dataflow' },
  ],
};

export const quicksightCustomTab: DatasourceTabV1 = {
  filters: [
    {
      indexes: [
        'quicksight_folders',
        'quicksight_reports',
        'quicksight_dashboards',
        'quicksight_bidatasets',
      ],
    },
  ],
  icon: tabConfig.quicksight.icon,
  name: tabConfig.quicksight.name,
  subtabs: [
    { indexes: ['quicksight_folders'], name: 'Folder' },
    { indexes: ['quicksight_reports'], name: 'Analysis' },
    { indexes: ['quicksight_dashboards'], name: 'Dashboard' },
    { indexes: ['quicksight_bidatasets'], name: 'Dataset' },
  ],
};

export const hexCustomTab: DatasourceTabV1 = {
  filters: [
    {
      indexes: ['hex_dashboards', 'hex_folders'],
    },
  ],
  icon: tabConfig.hex.icon,
  name: tabConfig.hex.name,
  subtabs: [
    { indexes: ['hex_folders'], name: 'Folder' },
    { indexes: ['hex_dashboards'], name: 'Dashboard' },
  ],
};

export const sigmaCustomTab: DatasourceTabV1 = {
  filters: [
    {
      indexes: ['sigma_dashboards', 'sigma_folders'],
    },
  ],
  icon: 'sigma',
  name: 'Sigma',
  subtabs: [
    { indexes: ['sigma_dashboards'], name: 'Workbook' },
    { indexes: ['sigma_folders'], name: 'Folder' },
  ],
};

export const tableauCustomTab: DatasourceTabV1 = {
  filters: [
    {
      indexes: ['tableau_data_sources', 'tableau_projects', 'tableau_workbooks'],
    },
  ],
  icon: 'tableau',
  name: 'Tableau',
  subtabs: [
    { indexes: ['tableau_workbooks'], name: 'Workbook' },
    { indexes: ['tableau_projects'], name: 'Project' },
    { indexes: ['tableau_data_sources'], name: 'Data Source' },
  ],
};

export const thoughtspotCustomTab: DatasourceTabV1 = {
  filters: [
    {
      indexes: [
        'thoughtspot_answers',
        'thoughtspot_liveboards',
        'thoughtspot_tables',
        'thoughtspot_worksheets',
        'thoughtspot_views',
      ],
    },
  ],
  icon: 'thoughtspot',
  name: 'Thoughtspot',
  subtabs: [
    { indexes: ['thoughtspot_answers'], name: 'Answer' },
    { indexes: ['thoughtspot_liveboards'], name: 'Liveboard' },
    { indexes: ['thoughtspot_tables'], name: 'Table' },
    { indexes: ['thoughtspot_worksheets'], name: 'Worksheet' },
    { indexes: ['thoughtspot_views'], name: 'View' },
  ],
};

export const accessToTabs = [
  {
    filters: [
      tabConfig.document.filters,
      databaseCustomTab.filters,
      lookerCustomTab.filters,
      tabConfig.mode.filters,
      sigmaCustomTab.filters,
      tabConfig.periscope.filters,
      powerbiCustomTab.filters,
      quicksightCustomTab.filters,
      metabaseCustomTab.filters,
      dataStudioCustomTab.filters,
      tableauCustomTab.filters,
      thoughtspotCustomTab.filters,
      hexCustomTab.filters,
    ].flat(),
    name: 'All',
  },
  tabConfig.document,
  databaseCustomTab,
  lookerCustomTab,
  tabConfig.mode,
  sigmaCustomTab,
  tabConfig.periscope,
  powerbiCustomTab,
  quicksightCustomTab,
  metabaseCustomTab,
  dataStudioCustomTab,
  tableauCustomTab,
  thoughtspotCustomTab,
  hexCustomTab,
];

export const exceptForFilters: QuickSearchIndexFilter[] = [
  {
    indexes: ['tags'],
  },
];

export const permissionRoleOptions = [
  {
    key: 'viewer',
    text: 'Viewer (View Only)',
    value: 'user',
  },
  {
    key: 'data-manager',
    text: 'Data Manager (Edit)',
    value: 'data_manager',
  },
  {
    key: 'admin',
    text: 'Admin (Configure)',
    value: 'admin',
  },
];
