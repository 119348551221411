import React, { useCallback, useState } from 'react';
import { IMPACT_SCORE_DESCRIPTION } from '@constants';
import { Cell } from 'react-table';

import type BiTableModel from '@api/biTables/BiTableModel';
import { TableModel } from '@api/tables/TableModel';
import { ObjectType } from '@api/types';
import { breadcrumbsToSimplifiedLabelList } from '@components/Breadcrumbs/Breadcrumbs.utils';
import DateTime from '@components/DateTime';
import NotApplicable from '@components/NotApplicable';
import DescriptionCell from '@components/Table/Cells/DescriptionCell';
import EditableTaggedItemCell from '@components/Table/Cells/EditableTaggedItemCell';
import FormattedNumberCell from '@components/Table/Cells/FormattedNumberCell';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import LocationCell from '@components/Table/Cells/LocationCell';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import RelatedObjectsCountCell from '@components/Table/Cells/RelatedObjectsCountsCell';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import UserCell from '@components/Table/Cells/UserCell';
import HeaderWithInfo from '@components/Table/Headers/HeaderWithInfo';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { DataSourceTypesType } from '@models/DataSourceCredentials';
import { Filter } from '@utils';
import { getPopularityNormalized } from '@utils/popularity';

export enum ColumnKey {
  businessOwner = 'businessOwner',
  createdBy = 'createdBy',
  description = 'description',
  downstreamDashboardsCount = 'downstreamDashboardsCount',
  downstreamObjectsCounts = 'downstreamObjectsCounts',
  impactScore = 'impactScore',
  location = 'location',
  name = 'name',
  popularity = 'popularity',
  rowCount = 'rowCount',
  search = 'search',
  sourceTablesCount = 'sourceTablesCount',
  tableSize = 'tableSize',
  tags = 'tags',
  technicalOwner = 'technicalOwner',
  updatedAt = 'updatedAt',
  upstreamObjectsCounts = 'upstreamObjectsCounts',
}

export const TABLES_TABLE_SEARCH_CONFIG: PartialRecord<ColumnKey, keyof Filter.FilterOptions> = {
  businessOwner: 'search_business_owner',
  createdBy: 'search_dsuser_created_by',
  description: 'search_description',
  name: 'search_name',
  tags: 'search_tags',
  technicalOwner: 'search_technical_owner',
};

export const TABLES_TABLE_SORT_CONFIG: PartialRecord<ColumnKey, string> = {
  businessOwner: 'business_owner',
  createdBy: 'dsuser_created_by',
  description: 'description',
  downstreamDashboardsCount: 'downstream_dashboards',
  downstreamObjectsCounts: 'downstream_objects_total',
  name: 'name',
  popularity: 'popularity',
  rowCount: 'row_count',
  sourceTablesCount: 'source_tables_count',
  tableSize: 'bytes',
  technicalOwner: 'technical_owner',
  updatedAt: 'table_updated_at',
  upstreamObjectsCounts: 'upstream_objects_total',
};

const defaultVisibleColumns = [
  ColumnKey.search,
  ColumnKey.name,
  ColumnKey.description,
  ColumnKey.tags,
  ColumnKey.businessOwner,
  ColumnKey.technicalOwner,
  ColumnKey.popularity,
  ColumnKey.impactScore,
  ColumnKey.downstreamObjectsCounts,
  ColumnKey.downstreamDashboardsCount,
  ColumnKey.upstreamObjectsCounts,
  ColumnKey.rowCount,
  ColumnKey.tableSize,
];

const defaultDbtVisibleColumns = [
  ColumnKey.search,
  ColumnKey.name,
  ColumnKey.description,
  ColumnKey.tags,
  ColumnKey.businessOwner,
  ColumnKey.technicalOwner,
  ColumnKey.popularity,
  ColumnKey.downstreamObjectsCounts,
  ColumnKey.upstreamObjectsCounts,
];

const defaultGlueVisibleColumns = [
  ColumnKey.search,
  ColumnKey.name,
  ColumnKey.location,
  ColumnKey.description,
  ColumnKey.tags,
  ColumnKey.businessOwner,
  ColumnKey.technicalOwner,
  ColumnKey.popularity,
  ColumnKey.downstreamObjectsCounts,
  ColumnKey.upstreamObjectsCounts,
];

export const TABLES_TABLE_DEFAULT_HIDDEN_COLUMNS = [
  ColumnKey.businessOwner,
  ColumnKey.technicalOwner,
  ColumnKey.tags,
  ColumnKey.downstreamObjectsCounts,
  ColumnKey.downstreamDashboardsCount,
  ColumnKey.upstreamObjectsCounts,
  ColumnKey.impactScore,
  ColumnKey.rowCount,
  ColumnKey.tableSize,
];

export interface TablesTableProps {
  customColumnProps?: PartialRecord<
    keyof typeof ColumnKey,
    Partial<Pick<ColumnConfig<TableModel>, 'dropdownCheckboxLabel'>>
  >;
  data?: TableModel[];
  dataSourceType?: DataSourceTypesType;
  filterService: Filter.FilterServiceInterface;
  hiddenColumns?: Array<keyof typeof ColumnKey>;
  hideNameLink?: boolean;
  initialSortState?: [{ desc: boolean; id: ColumnKey }];
  isDataSourceEditable: boolean;
  isLoading?: boolean;
  itemCount?: number;
  pageObjectType?: ObjectType;
  selectedRowIds: { [guid: string]: boolean };
  showBreadcrumbs?: boolean;
  toggleAll?: (checked: boolean) => void;
  toggleItem?: (item: TableModel, checked: boolean) => void;
  useGlobalSearch?: boolean;
  visibleColumns?: Array<keyof typeof ColumnKey>;
}

const TablesTable: React.FC<TablesTableProps> = ({
  customColumnProps,
  data,
  dataSourceType,
  filterService,
  hiddenColumns = TABLES_TABLE_DEFAULT_HIDDEN_COLUMNS,
  hideNameLink = true,
  initialSortState = [
    {
      desc: true,
      id: 'popularity',
    },
  ],
  isDataSourceEditable,
  isLoading,
  itemCount = 0,
  pageObjectType,
  selectedRowIds,
  showBreadcrumbs,
  toggleAll,
  toggleItem,
  useGlobalSearch = true,
  visibleColumns = defaultVisibleColumns,
}) => {
  const [isShowFilter, setShowFilter] = useState(false);
  const toggleFilter = useCallback(() => setShowFilter((prev) => !prev), [setShowFilter]);
  const { changePage, filter, globalSearch, search, sort } = filterService;

  const columns: ColumnConfig<TableModel>[] = React.useMemo(() => {
    const all: Record<ColumnKey, ColumnConfig<TableModel>> = {
      businessOwner: {
        Cell: ({ row: { original } }: Cell<TableModel>) => (
          <UserCell user={original.businessOwner?.obj} />
        ),
        Header: 'Business Owner',
        accessor: (d) => d.businessOwner?.obj?.fullName,
        disableFilters: true,
        id: 'businessOwner',
        width: '120%',
      },
      createdBy: {
        Cell: ({
          column,
          globalFilter,
          row: { original },
          state,
        }: Cell<TableModel> & { globalFilter: string }) => (
          <UserCell
            column={column}
            ellipsis
            globalFilter={globalFilter}
            state={state}
            user={original.dsuserCreatedBy}
          />
        ),
        Header: 'Created By',
        accessor: (d) => d.dsuserCreatedBy?.fullName,
        id: 'createdBy',
        width: '150%',
      },
      description: {
        Cell: (props: Cell<TableModel>) => {
          const {
            column,
            row: { original },
            state,
          } = props;
          return (
            <DescriptionCell
              {...props}
              {...original}
              column={column}
              dataSourceType={original.dataTypes?.dataSourceType}
              isDataSourceEditable={isDataSourceEditable}
              state={state}
              suggestedDescriptionSourceObj={original.suggestedDescriptionSourceObject?.obj}
            />
          );
        },
        Header: 'Description',
        accessor: 'description',
        disableFilters: useGlobalSearch,
        id: 'description',
        width: '135%',
      },
      downstreamDashboardsCount: {
        Cell: ({ row: { original } }: Cell<TableModel>) => (
          <FormattedNumberCell number={original.downstreamObjectsCounts?.dashboard ?? 0} />
        ),
        Header: (
          <HeaderWithInfo
            description="Count of downstream dashboards from data lineage"
            name="Downstream Dashboards"
          />
        ),
        accessor: (d) => d.downstreamObjectsCounts?.dashboard,
        disableFilters: true,
        dropdownCheckboxLabel: 'Downstream Dashboards',
        id: 'downstreamDashboardsCount',
        sortDescFirst: true,
        width: 220,
      },
      downstreamObjectsCounts: {
        Cell: ({ row: { original } }: Cell<TableModel>) => (
          <RelatedObjectsCountCell counts={original.downstreamObjectsCounts} />
        ),
        Header: (
          <HeaderWithInfo
            description="Count of downstream tables and dashboards from data lineage"
            name="Downstream"
          />
        ),
        accessor: (d) => d.downstreamObjectsCounts?.total,
        disableFilters: true,
        dropdownCheckboxLabel: 'Downstream',
        id: 'downstreamObjectsCounts',
        sortDescFirst: true,
        width: 140,
      },
      impactScore: {
        Cell: ({ row: { original } }: Cell<TableModel>) => (
          <FormattedNumberCell number={original.downstreamObjectsCounts?.impactScore} />
        ),
        Header: <HeaderWithInfo description={IMPACT_SCORE_DESCRIPTION} name="Impact Score" />,
        accessor: (d) => d.downstreamObjectsCounts?.impactScore,
        disableFilters: true,
        disableHiding: false,
        dropdownCheckboxLabel: 'Impact Score',
        id: 'impactScore',
        sortDescFirst: true,
        width: 140,
      },
      location: {
        Cell: ({ row: { original } }: Cell<TableModel>) => (
          <LocationCell location={original.cloudObject?.location} />
        ),
        Header: 'Location',
        accessor: (d: Partial<TableModel>) => d.cloudObject?.location,
        disableFilters: true,
        disableSortBy: true,
        id: 'location',
        width: '130%',
      },
      name: {
        Cell: ({
          column,
          globalFilter,
          row: { original: table },
          state,
        }: Cell<TableModel> & { globalFilter: string }) => {
          const tableName = table?.schema ? `${table?.schema?.name}.${table?.name}` : table?.name;

          const simplifiedBreadcrumbs = breadcrumbsToSimplifiedLabelList({
            breadcrumbs: table.breadcrumbs,
            label: table.name,
            pageObjectType,
            url: table.routePath,
          });

          const item = {
            ...table,
            breadcrumbLabelList: simplifiedBreadcrumbs,
          };

          return (
            <LinkedCell
              breadcrumbFixedUrl={table.routePath}
              column={column}
              globalFilter={globalFilter}
              item={item}
              itemName={showBreadcrumbs ? undefined : tableName}
              noLink={hideNameLink}
              showBreadcrumbs={showBreadcrumbs}
              showDataTypeTooltip
              showIcon
              state={state}
            />
          );
        },
        Header: `Name (${itemCount})`,
        accessor: (d) => `${d?.schema?.name}.${d.name}`,
        disableHiding: true,
        id: 'name',
        width: '135%',
      },
      popularity: {
        Cell: ({ row: { original } }: Cell<TableModel>) => (
          <PopularityCell popularity={original?.popularity} />
        ),
        Header: PopularityCellHeader,
        accessor: (d) => getPopularityNormalized(d?.popularity?.popularity),
        disableFilters: true,
        dropdownCheckboxLabel: customColumnProps?.popularity?.dropdownCheckboxLabel,
        id: 'popularity',
        sortDescFirst: true,
        width: 120,
      },
      rowCount: {
        Cell: ({ row: { original } }: Cell<TableModel>) =>
          original.formattedRowCount ?? <NotApplicable />,
        Header: 'Row Count',
        accessor: 'formattedRowCount',
        disableFilters: true,
        id: 'rowCount',
        sortDescFirst: true,
        width: '110%',
      },
      search: {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      sourceTablesCount: {
        Header: (
          <HeaderWithInfo
            description="Count of upstream data tables connected to this dashboard / report"
            name="Source Tables"
          />
        ),
        accessor: (d) => d.sourceTablesCount,
        disableFilters: true,
        dropdownCheckboxLabel: 'Source Tables',
        id: 'sourceTablesCount',
        sortDescFirst: true,
        width: 170,
      },
      tableSize: {
        Cell: ({ row: { original } }: Cell<TableModel>) =>
          original.formattedBytes ?? <NotApplicable />,
        Header: 'Table Size',
        accessor: 'formattedBytes',
        disableFilters: true,
        id: 'tableSize',
        sortDescFirst: true,
        width: '110%',
      },
      tags: {
        Cell: (props: Cell<TableModel>) => {
          const { row } = props;
          const table = row.original;
          return (
            <EditableTaggedItemCell
              {...props}
              isDataSourceEditable={isDataSourceEditable}
              obj={table}
            />
          );
        },
        Header: 'Tags',
        accessor: (d) => d.taggedItems,
        disableFilters: true,
        disableSortBy: true,
        id: 'tags',
        width: '130%',
      },
      technicalOwner: {
        Cell: ({ row: { original } }: Cell<TableModel>) => (
          <UserCell user={original.technicalOwner?.obj} />
        ),
        Header: 'Technical Owner',
        accessor: (d) => d.technicalOwner?.obj?.fullName,
        disableFilters: true,
        id: 'technicalOwner',
        width: '120%',
      },
      updatedAt: {
        Cell: ({ row: { original } }: Cell<BiTableModel>) => {
          return <DateTime datetime={original.updatedAt} />;
        },
        Header: 'Last Modified',
        accessor: (d) => (d as BiTableModel)?.updatedAt?.toDate(),
        disableFilters: true,
        id: 'updatedAt',
        sortType: 'datetime',
        width: '118%',
      },
      upstreamObjectsCounts: {
        Cell: ({ row: { original } }: Cell<TableModel>) => (
          <RelatedObjectsCountCell counts={original.upstreamObjectsCounts} />
        ),
        Header: (
          <HeaderWithInfo
            description="Count of upstream objects from data lineage"
            name="Upstream"
          />
        ),
        accessor: (d) => d.upstreamObjectsCounts?.total,
        disableFilters: true,
        dropdownCheckboxLabel: 'Upstream',
        id: 'upstreamObjectsCounts',
        sortDescFirst: true,
        width: 120,
      },
    };

    const getColumns = () => {
      switch (dataSourceType) {
        case 'dbt':
          return defaultDbtVisibleColumns;
        case 'glue':
          return defaultGlueVisibleColumns;
        default:
          return visibleColumns;
      }
    };

    return getColumns().map((col) => all[col]);
  }, [
    customColumnProps,
    dataSourceType,
    pageObjectType,
    hideNameLink,
    isDataSourceEditable,
    itemCount,
    showBreadcrumbs,
    useGlobalSearch,
    visibleColumns,
  ]);

  const totalPages = data && filter.page_size ? Math.ceil(itemCount / filter.page_size) : 1;
  const getRowId = React.useCallback((row: Partial<TableModel>) => row.guid!, []);

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        compact
        data={data || []}
        getRowId={getRowId}
        initialState={{
          hiddenColumns,
          pageIndex: filter.page ? filter.page - 1 : 0,
          selectedRowIds,
          sortBy: initialSortState,
        }}
        loading={isLoading}
        manualFilters
        manualGlobalFilter={useGlobalSearch}
        manualPagination
        manualSortBy
        rangeSelectConfig={{
          onCellCheckChange: toggleItem,
          onHeaderCheckChange: toggleAll,
        }}
        selectable
        setFilters={search}
        setGlobalFilter={globalSearch}
        setSortBy={sort}
        showFilter={isShowFilter}
        sortable
        stickyHeader
        toggleFilter={toggleFilter}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default React.memo<TablesTableProps>(TablesTable);
