import rawTransform from '@api/rawTransform';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';
import { HierarchyData, HierarchyModel } from '@models/HierarchyModel';

import * as cacheKeys from './cacheKeys';
import mapCollectionModel, { CollectionModel } from './CollectionModel';

export const collectionsHierarchySelect = rawTransform(HierarchyModel);
export const useFetchCollectionsHierarchy = (options?: UseFetchOptions<HierarchyData[]>) => {
  return useFetch<HierarchyData[]>({
    ...options,
    initialData: collectionsHierarchyRawMock as unknown as HierarchyData[],
    queryKey: [...cacheKeys.hierarchy],
    select: collectionsHierarchySelect,
    url: '/collections/hierarchy/',
  });
};

const fetchCollectionSelect = rawTransform(mapCollectionModel);
export const usePatchCollection = (id?: string, options?: UseMutationOptions) => {
  return useMutation<CollectionModel>({
    ...options,
    method: 'PATCH',
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(fetchCollectionSelect(data), variables, context);
    },
    url: id ? `/collections/${id}/` : undefined,
  });
};

export const collectionsHierarchyRawMock = [
  {
    children: [
      {
        children: [],
        data_types: {
          data_source_type: 'collection',
          data_type: null,
          object_type: 'collection',
        },
        guid: 'do_1o5pCqA78RT2LeMyttbkDkF',
        name: 'Business 1 (mock)',
        object_type: 'collection',
        ordinal: 0,
        type: 'collection',
      },
    ],
    data_types: {
      data_source_type: 'collection',
      data_type: null,
      object_type: 'collection',
    },
    guid: 'do_0nev5hxHx8cw3GAygQWUor5',
    name: 'Business 0 (mock)',
    object_type: 'collection',
    ordinal: 0,
    type: 'collection',
  },
  {
    children: [],
    data_types: {
      data_source_type: 'collection',
      data_type: null,
      object_type: 'collection',
    },
    guid: 'do_nev5hxHx8cw3GAygQWUor5',
    name: 'Marketing (mock)',
    object_type: 'collection',
    ordinal: 0,
    type: 'collection',
  },
  {
    children: [],
    data_types: {
      data_source_type: 'collection',
      data_type: null,
      object_type: 'collection',
    },
    guid: 'do_1PmyMGcnuTEnpUjzzrxnUFL',
    name: 'Ops (mock)',
    object_type: 'collection',
    ordinal: 1,
    type: 'collection',
  },
  {
    children: [],
    data_types: {
      data_source_type: 'collection',
      data_type: null,
      object_type: 'collection',
    },
    guid: 'do_0PmyMGcnuTEnpUjzzrxnUFL',
    name: 'Product (mock)',
    object_type: 'collection',
    ordinal: 1,
    type: 'collection',
  },
];
