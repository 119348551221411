import React from 'react';

import LayoutMainGrid from '@components/Layout/LayoutMainGrid';
import MetadataDecorator from '@utils/MetadataDecorator';

const CollectionsPage: React.FC = () => {
  return (
    <>
      <MetadataDecorator title="Collections" />
      <LayoutMainGrid>Collections page mock</LayoutMainGrid>
    </>
  );
};

export default CollectionsPage;
