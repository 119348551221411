import React from 'react';

import Button from '@components/Button/Button';
import theme from '@styles/theme';

const CreateCollectionButton: React.FC = () => {
  return (
    <Button
      compHeight={theme.space(4.5)}
      compSize="lg"
      compWidth="100%"
      justifyContent="center"
      onClick={() => {}}
      pl={1.25}
      spacing={0.5}
      startIconName="plus"
      startIconSize="16px"
      textAlign="left"
      variant="outlined"
    >
      New Collection
    </Button>
  );
};

export default CreateCollectionButton;
