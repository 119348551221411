import React from 'react';
import { RouterLink, useParams } from '@routing/router';

import { useFetchCollectionsHierarchy, usePatchCollection } from '@api/collections';
import invalidateCache from '@api/invalidateCache';
import { searchCacheKeys } from '@api/search';
import Box from '@components/Box';
import { renderErrorToast } from '@components/Toast';
import getUrl from '@components/Tree/getUrl';
import { renderIndentationLines } from '@components/Tree/Tree.v1';
import TreeNode from '@components/Tree/TreeNode.v1';
import Icon from '@components/UI/Icon';
import { useUserContext } from '@context/User';
import fetchClient from '@lib/fetchClient';
import theme from '@styles/theme';

import HierarchyLoadingSkeleton from '../HierarchyLoadingSkeleton';
import RoutedHierarchyTree from '../RoutedHierarchyTree';

import CreateCollectionButton from './CreateCollectionButton';

const CollectionsHierarchy: React.FC = () => {
  const { hasEditPermissions = false } = useUserContext();

  const { data, isLoading } = useFetchCollectionsHierarchy();

  const { mutate } = usePatchCollection(undefined, {
    onError: (error) => {
      if (error?.status === 403) {
        renderErrorToast('Permission denied. Unable to move collection.');
      }
    },
    onSuccess: (d) => {
      invalidateCache((keys) => [keys.collections.all]);
      fetchClient.setQueryData(searchCacheKeys.searchItem(d.guid), d);
    },
  });

  const { guid } = useParams<{ guid: string }>();

  if (isLoading) {
    return <HierarchyLoadingSkeleton />;
  }

  return (
    <>
      {hasEditPermissions && (
        <Box mb={1} px={1.5}>
          <CreateCollectionButton />
        </Box>
      )}
      <Box overflowX="hidden" overflowY="auto" pl={0.5} pr={0.25}>
        <RoutedHierarchyTree
          allowDragDrop={hasEditPermissions}
          defaultExpandedKeys={[guid]}
          defaultSelectedKeys={[guid]}
          draggable={hasEditPermissions}
          keyAlias="guid"
          onDrop={({ dragNode, parent }) => {
            if (parent) {
              mutate({
                httpClientUrl: `/collections/${dragNode.key}/`,
                parent_hierarchy: parent,
              });
            }
          }}
          treeData={data}
          useNewLayout
        >
          {({ children, item }) => {
            const { level = 1, ordinal } = item;
            const normalizedLevel = level - 1;
            const paddingLeft = theme.space(1 + normalizedLevel * 2.5);

            return (
              <TreeNode
                key={item.guid}
                icon={
                  <RouterLink to={getUrl(item) ?? '#'}>
                    {item.iconEl ?? (
                      <Icon
                        color="currentcolor"
                        name={item.dataTypes?.icons.dataType!}
                        size="16px"
                      />
                    )}
                  </RouterLink>
                }
                style={{
                  paddingLeft,
                  paddingRight: theme.space(1),
                }}
                title={
                  <RouterLink to={getUrl(item) ?? '#'}>
                    {renderIndentationLines({
                      isFirstChild: ordinal === 0,
                      level: normalizedLevel,
                    })}
                    {item.name}
                  </RouterLink>
                }
              >
                {children}
              </TreeNode>
            );
          }}
        </RoutedHierarchyTree>
      </Box>
    </>
  );
};

export default CollectionsHierarchy;
