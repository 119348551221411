import { Monitor } from '@api/openapi.generated';
import type { ObjectType } from '@api/types';
import mapDataTypesModel from '@models/DataTypesModel/DataTypesModel.v1';

const objectType: ObjectType = 'monitor';

const mapCollectionModel = (data: Monitor) => {
  return {
    dataTypes: mapDataTypesModel(data.data_types),
    guid: data.guid,
    name: data.name,
  };
};

export default mapCollectionModel;

mapCollectionModel.objectType = objectType;

export type CollectionModel = ReturnType<typeof mapCollectionModel>;
